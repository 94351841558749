// import store from '../store';
import router from '../router';
import VueCookies from 'vue-cookies';
import toast from './toast';
import pinia from './pinia';

const plugins = [router, /*store,*/ VueCookies, toast, pinia];

export default plugins.map((plugin) => {
    return plugin.options !== undefined && Array.isArray(plugin.options)
        ? {
              plugin: plugin.plugin,
              options: plugin.options,
          }
        : { plugin, options: [] };
});
