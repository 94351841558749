import { isMobile } from 'mobile-device-detect';

export default function auth(to, from, next) {
    const isLogged = !!localStorage.getItem('token') && !!localStorage.getItem('pin');
    const isIframe = window.location !== window.parent.location;

    if (isIframe && isLogged) {
        window.parent.postMessage(
            {
                status: 'success',
                track: localStorage.getItem('track'),
                token: localStorage.getItem('token'),
                pin: localStorage.getItem('pin'),
                loginInfo: localStorage.getItem('loginInfo'),
                nif: localStorage.getItem('nif'),
                phone: localStorage.getItem('phone'),
            },
            '*',
        );
    }

    if (!isLogged) {
        // Caso de navegación normal
        return next({
            name: 'login',
        });
    }

    // Usuario logged, hacemos la siguiente petición
    if (isMobile) {
        //Si tiene cookie de logout, eliminamos la cookie.
        if (document.cookie.indexOf('area_clientes_logout') >= 0) {
            document.cookie =
                'area_clientes_logout=;expires= Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=aquaservice.com';
        }
    }
    return next();
}

/*
    next() // move on to the next hook in the pipeline. If no hooks are left, the navigation is confirmed.
    next(false) // abort the current navigation.
    next('/') // redirect to a different location.
 */
