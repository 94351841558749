class Env {
    constructor() {
        const env = import.meta.env;
        this.env = Object.keys(env).reduce((acc, key) => {
            const prefix = key.split('_')[0];
            if (prefix === 'VITE') {
                const name = key.split('_').slice(1).join('_');
                if (name === 'ENVIRONMENT') acc[name] = env[key].toUpperCase();
                else acc[name] = env[key];
            }
            return acc;
        }, {});
    }

    getEnv() {
        const env = localStorage.getItem('env');
        const isValidEnv = ['DEV', 'PRE', 'PROD'].includes(env);
        if (isValidEnv && window.location.hostname !== 'clientes.aquaservice.com') return env;
        return this.env.ENVIRONMENT;
    }

    getRealEnv() {
        if (window.location.hostname === 'clientes.aquaservice.com') return 'PROD';
        if (window.location.hostname === 'devclientes.aquaservice.com') return 'DEV';
        if (window.location.hostname === 'preclientes.aquaservice.com') return 'PRE';
        if (window.location.hostname === 'localhost') return 'LOCAL';
        return this.getEnv();
    }

    getRealEnvName() {
        let environments = {
            PROD: 'production',
            PRE: 'staging',
            DEV: 'development',
            LOCAL: 'local',
        };
        if (this.getRealEnv() in environments) {
            return environments[this.getRealEnv()];
        }
        return this.getRealEnv();
    }

    getBaseUrl() {
        return this.env[`URI_${this.getEnv()}`];
    }

    getBaseHost() {
        return this.env[`HOST_${this.getEnv()}`];
    }

    getApiVersion() {
        return this.env[`API_VERSION`];
    }

    getSentryDSN() {
        return this.env[`SENTRY_DSN`];
    }

    getFirebaseConfig() {
        let suffix = 'DEV';
        if (this.getEnv() === 'PROD') suffix = 'PROD';
        return {
            apiKey: this.env[`FIREBASE_API_KEY_${suffix}`],
            authDomain: this.env[`FIREBASE_AUTH_DOMAIN_${suffix}`],
            databaseURL: this.env[`FIREBASE_DATABASE_URL_${suffix}`],
            projectId: this.env[`FIREBASE_PROJECT_ID_${suffix}`],
            storageBucket: this.env[`FIREBASE_STORAGE_BUCKET_${suffix}`],
            messagingSenderId: this.env[`FIREBASE_MESSAGING_SENDER_ID_${suffix}`],
            appId: this.env[`FIREBASE_APP_ID_${suffix}`],
            measurementId: this.env[`FIREBASE_MEASUREMENT_ID_${suffix}`],
        };
    }
}

export default Env;
