import * as Sentry from '@sentry/vue';
import router from './router';
import Env from '@/env';

const env = new Env();

export const useSentry = (app) => {
    Sentry.init({
        app,
        dsn: env.getSentryDSN(),
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        tracePropagationTargets: [
            'localhost',
            'clientes.aquaservice.com',
            'devappclient.aquaservice.com',
            'preappclient.aquaservice.com',
            'appclient.aquaservice.com',
            /^\//,
        ],
        environment: env.getRealEnvName(),
        tracesSampleRate: 1.0,

        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};
