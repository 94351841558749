import Toast, { TYPE as toastType } from 'vue-toastification';

export default {
    plugin: Toast,
    options: [
        {
            position: 'top-right',
            timeout: 5000,
            transition: 'Vue-Toastification__bounce',
            showCloseButtonOnHover: true,
            filterBeforeCreate: (toast, toasts) => {
                if (toasts.filter((t) => t.type === toast.type).length !== 0) {
                    return false;
                }
                return toast;
            },
            toastDefaults: {
                [toastType.DEFAULT]: {
                    icon: {
                        iconClass: 'material-icons-round',
                        iconTag: 'span',
                        iconChildren: 'info',
                    },
                },
                [toastType.SUCCESS]: {
                    icon: {
                        iconClass: 'material-icons-round',
                        iconTag: 'span',
                        iconChildren: 'check_circle',
                    },
                },
                [toastType.ERROR]: {
                    icon: {
                        iconClass: 'material-icons-round',
                        iconTag: 'span',
                        iconChildren: 'error',
                    },
                },
                [toastType.INFO]: {
                    icon: {
                        iconClass: 'material-icons-round',
                        iconTag: 'span',
                        iconChildren: 'info',
                    },
                },
                [toastType.WARNING]: {
                    icon: {
                        iconClass: 'material-icons-round',
                        iconTag: 'span',
                        iconChildren: 'warning',
                    },
                },
            },
        },
    ],
};
