// import Vue from 'vue';

function capitalize(value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

function amount(value) {
    return Number(value).toLocaleString('es-ES');
}

function currency(value) {
    return Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'EUR',
    }).format(value);
}

function lowercase(value) {
    return value.toLowerCase();
}

function uppercase(value) {
    return value.toUpperCase();
}

export default {
    amount,
    capitalize,
    currency,
    lowercase,
    uppercase,
};
