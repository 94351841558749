import { isMobile, isAndroid } from 'mobile-device-detect';
const isIframe = window.location !== window.parent.location;
const isLogged = !!localStorage.getItem('token') && !!localStorage.getItem('pin');

export default function auth(to, from, next) {
    if (isLogged || isIframe) return next();
    if (isMobile) {
        //const store = 'https://play.google.com/store/apps/details?id=com.aquaservice&hl=es'
        //setTimeout(() => window.location = "https://itunes.apple.com/es/app/aquaservice/id986255140?mt=8", 250);
        if (isAndroid) {
            window.location = 'aquaservice://';
        }
        //window.location = "aquaservice://";
        return next({
            name: 'mobile',
        });
    }

    return next();
}
